body { background-color: #ffffff; }
body { color: var(--clr-71563); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-71563);
font-family: 'Raleway';
font-weight: 900;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-71563);
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-71563);
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-71563);
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 23px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-71563);
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-71563);
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 26px;

}
@media #{$large-up} {
font-size: 34px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-71563);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 15;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
summary {
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
/* Primary:2 */
.MES2 {
background-color: var(--clr-71563);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-71563);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-71563);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: var(--clr-71564);
&:hover { color: #ffffff;}
 }
cite.MEC3{
color: #ffffff;
}
/* Footer:4 */
.MES4 {
background-color: var(--clr-71563);
color: #ffffff;
font-size: 12.8px;
 }
.MES4 {
background-color: var(--clr-71563);
color: #ffffff;
font-size: 12.8px;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
a.MEC4 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC4{
color: #ffffff;
font-size: 12.8px;
}
/* White Button:5 */
.MES5 {
background-color: transparent;
&:hover {background-color: var(--clr-71565);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 18px;
@media #{$large-up} {
font-size: 20px;
};
border-width: 5px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Shade 1:6 */
.MES6 {
background-color: var(--clr-71566);
color: var(--clr-71563);
 }
/* Shade 1:7 */
.MES7 {
background-color: var(--clr-71566);
color: var(--clr-71563);
 }
.MES7 {
background-color: var(--clr-71566);
color: var(--clr-71563);
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: var(--clr-71563);
 }
 }
cite.MEC7{
color: var(--clr-71563);
}
/* Shade 2:8 */
.MES8 {
background-color: var(--clr-71567);
 }
/* Shade 2:9 */
.MES9 {
background-color: var(--clr-71567);
 }
.MES9 {
background-color: var(--clr-71567);
 }
/* Shade 2:10 */
.MES10 {
background-color: var(--clr-71567);
color: var(--clr-71563);
 }
/* Shade 2:11 */
.MES11 {
background-color: var(--clr-71567);
color: var(--clr-71563);
 }
.MES11 {
background-color: var(--clr-71567);
color: var(--clr-71563);
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: var(--clr-71563);
 }
 }
cite.MEC11{
color: var(--clr-71563);
}
/* Shade 3:12 */
.MES12 {
background-color: var(--clr-71564);
color: var(--clr-71563);
 }
/* Shade 3:13 */
.MES13 {
background-color: var(--clr-71564);
color: var(--clr-71563);
 }
.MES13 {
background-color: var(--clr-71564);
color: var(--clr-71563);
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: var(--clr-71563);
 }
 }
cite.MEC13{
color: var(--clr-71563);
}
/* Alternate:14 */
.MES14 {
background-color: var(--clr-71568);
color: #ffffff;
 }
/* Alternate:15 */
.MES15 {
background-color: var(--clr-71568);
color: #ffffff;
 }
.MES15 {
background-color: var(--clr-71568);
color: #ffffff;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
/* Secondary:16 */
.MES16 {
background-color: var(--clr-71569);
color: #ffffff;
 }
/* Secondary:17 */
.MES17 {
background-color: var(--clr-71569);
color: #ffffff;
 }
.MES17 {
background-color: var(--clr-71569);
color: #ffffff;
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: #ffffff;
 }
 }
cite.MEC17{
color: #ffffff;
}
/* Dark:18 */
.MES18 {
background-color: var(--clr-71570);
 }
/* Dark:19 */
.MES19 {
background-color: var(--clr-71570);
color: #ffffff;
 }
/* Dark:20 */
.MES20 {
background-color: var(--clr-71570);
 }
.MES20 {
background-color: var(--clr-71570);
 }
/* Dark:21 */
.MES21 {
background-color: var(--clr-71570);
color: #ffffff;
 }
.MES21 {
background-color: var(--clr-71570);
color: #ffffff;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
 }
cite.MEC21{
color: #ffffff;
}
/* home block panels:22 */
.MES22 {
background-color: var(--clr-71563);
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES22 {
background-color: var(--clr-71563);
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: #ffffff;
 }
 }
.MEC22 li {color: #ffffff;}
cite.MEC22{
color: #ffffff;
}
/* Background Image Panel:23 */
.MES23 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://sherwoodsportswear.com.au/img/59134/24');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES23 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://sherwoodsportswear.com.au/img/59134/24');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: #ffffff;
 }
 }
.MEC23 li {color: #ffffff;}
cite.MEC23{
color: #ffffff;
}
/* Category Panel:24 */
.MES24 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-71567);}
color: var(--clr-71563);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-71566);
 }
.MES24 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-71567);}
color: var(--clr-71563);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-71566);
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-71563);
 }
 }
a.MEC24 { color: var(--clr-71563);
 }
cite.MEC24{
color: var(--clr-71563);
}
/* Button Black:25 */
.MES25 {
background-color: var(--clr-71565);
&:hover {background-color: var(--clr-71563);}
color: var(--clr-71568);
&:hover { color: #ffffff;}
font-size: 18px;
@media #{$large-up} {
font-size: 20px;
};
padding: 15px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-71563);
&:hover { border-color: var(--clr-71563); }
 }
/* Cart Checkout Button:26 */
.MES26 {
background-color: transparent;
&:hover {background-color: var(--clr-71565);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.6px;
};
@media #{$large-up} {
font-size: 14px;
};
padding: 5px 15px;

border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Roll over:27 */
.MES27 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-71571);}
color: #ffffff;
 }
.MES27 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-71571);}
color: #ffffff;
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: #ffffff;
 }
 }
cite.MEC27{
color: #ffffff;
}
/* Overlay Rollover:28 */
.MES28 {
background-color: var(--clr-71572);
&:hover, &.hover { background-color: var(--clr-71571);}
color: #ffffff;
 }
.MES28 {
background-color: var(--clr-71572);
&:hover, &.hover { background-color: var(--clr-71571);}
color: #ffffff;
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
cite.MEC28{
color: #ffffff;
}
/* White Heading Button:29 */
.MES29 {
background-color: transparent;
&:hover {background-color: var(--clr-71565);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 27px;
@media #{$large-up} {
font-size: 30px;
};
padding: 0 5px;

border-width: 5px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Header scroll:30 */
.MES30 {
background-color: var(--clr-71573);
border-width: 0 0 4px 0;
border-style: solid;
border-color: transparent transparent var(--clr-71567) transparent;
 }
.MES30 {
background-color: var(--clr-71573);
border-width: 0 0 4px 0;
border-style: solid;
border-color: transparent transparent var(--clr-71567) transparent;
 }
/* Main Menu:31 */
nav.me-Menu.MES31 {
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: var(--clr-71563);
font-size: 19.2px;
}
 &:hover > a.MEC31{color: var(--clr-71570);
}
 }
&.horizontal > .menu-item.MEC31 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC31 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC31 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 20;
border-style: solid;
 }
&.horizontal > .menu-item.MEC31 .sub-menu { border:1px;
 }
&.horizontal .menu-item.MEC31:hover { & > .sub-menu {transform: none !important;
  text-align: left;
 left: 0;
}}
& > .menu-item > a{padding: 0 20px;}

& .sub-menu .menu-item a{padding: 10px 20px;}



&.horizontal .menu-item.MEC31:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-71566-flat);}}
&.vertical .menu-item.MEC31:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-71566-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC31{background-color: var(--clr-71566); &:hover {background-color: 18;}
}
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: var(--clr-71563);
font-size: 16px;
}
  }

}
}
 }
/* Background Image Panel:32 */
.MES32 {
background-color: var(--clr-71569);
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES32 {
background-color: var(--clr-71569);
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: #ffffff;
 }
 }
.MEC32 li {color: #ffffff;}
cite.MEC32{
color: #ffffff;
}
/* Content block Grey:33 */
.MES33 {
background-color: var(--clr-71566);
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 50px 20px;

}
 }
.MES33 {
background-color: var(--clr-71566);
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 50px 20px;

}
 }
/* Separator:34 */
.MES34 {
padding: 15px;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-71564) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Drop File Area:36 */
.me-block > .field-wrap.MES36 { 
& label {  }
 }
/* Drop File Style:37 */
.MES37 {
color: var(--clr-71570);
font-size: 19.2px;
@media #{$medium-up} {
font-size: 20.8px;
};
padding: 30px 20px;

@media #{$large-up} {
padding: 40px;

}
border-width: 3px;
border-style: solid;
border-color: var(--clr-71564);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES37 {
color: var(--clr-71570);
font-size: 19.2px;
@media #{$medium-up} {
font-size: 20.8px;
};
padding: 30px 20px;

@media #{$large-up} {
padding: 40px;

}
border-width: 3px;
border-style: solid;
border-color: var(--clr-71564);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
cite.MEC37{
color: var(--clr-71570);
font-size: 19.2px;
@media #{$medium-up} {
font-size: 20.8px;
};
}
/* Quote Button:38 */
.MES38 {
background-color: var(--clr-71565);
&:hover {background-color: var(--clr-71563);}
color: var(--clr-71563);
&:hover { color: #ffffff;}
font-size: 21.6px;
@media #{$medium-up} {
font-size: 23.4px;
};
@media #{$large-up} {
font-size: 26px;
};
padding: 0 5px;

 }
/* Snipet Panel:39 */
.MES39 {
padding: 15px;

 }
.MES39 {
padding: 15px;

 }
/* Cart button:40 */
.MES40 {
 & .cart-panel{  }
 color: #ffffff;
font-size: 20px;
@media #{$medium-up} {
font-size: 16px;
}}/* Category Button:41 */
.MES41 {
background-color: transparent;
&:hover {background-color: var(--clr-71563);}
color: var(--clr-71563);
&:hover { color: #ffffff;}
font-size: 12.6px;
@media #{$large-up} {
font-size: 14px;
};
padding: 0 5px;

border-width: 2px;
border-style: solid;
border-color: var(--clr-71563);
 }
/* Responsive menu:42 */
nav.responsive-menu {
.menu-item.MEC42{background-color: var(--clr-71567);}
& .menu-item.MEC42, & .menu-item.MEC42 > div.menu-item-wrap{ & > a.MEC42, & > i{color: var(--clr-71563);
text-align: left;
}
  }
& .menu-item.MEC42 { border:0;
border-color: var(--clr-71564);border-style: solid;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 10px;}

& .sub-menu{ .sub-menu {}}

 }
/* Footer2:43 */
.MES43 {
background-color: var(--clr-71563);
color: #ffffff;
font-size: 14.4px;
 }
.MES43 {
background-color: var(--clr-71563);
color: #ffffff;
font-size: 14.4px;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
a.MEC43 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC43{
color: #ffffff;
font-size: 14.4px;
}
/* Category Menu:44 */
nav.me-Menu.MES44 {
.menu-item.MEC44{background-color: var(--clr-71567); &:hover {background-color: var(--clr-71564);}
}
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: var(--clr-71563);
}
  }
&.horizontal > .menu-item.MEC44 { border:0;
border-color: #ffffff;
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC44 { border:0;
border-color: #ffffff;
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC44 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC44 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Carousel Image:45 */
.MES45 {
padding: 30px;

 }
.MES45 {
padding: 30px;

 }
/* Content block white:46 */
.MES46 {
background-color: #ffffff;
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 50px 20px;

}
 }
.MES46 {
background-color: #ffffff;
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 50px 20px;

}
 }
/* Content block Black:47 */
.MES47 {
background-color: var(--clr-71563);
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 50px 20px;

}
 }
.MES47 {
background-color: var(--clr-71563);
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 50px 20px;

}
 }
/* Standout text :48 */
.MES48 {
font-size: 17.6px;
@media #{$medium-up} {
font-size: 18.4px;
};
@media #{$large-up} {
font-size: 20px;
};
 }
.MES48 {
font-size: 17.6px;
@media #{$medium-up} {
font-size: 18.4px;
};
@media #{$large-up} {
font-size: 20px;
};
 }
cite.MEC48{
font-size: 17.6px;
@media #{$medium-up} {
font-size: 18.4px;
};
@media #{$large-up} {
font-size: 20px;
};
}
/* FAQ's:49 */
details.MES49 {
& > summary{background-color: var(--clr-71566);
&:hover {background-color: var(--clr-71567);}
}
& > summary{padding: 15px;}

& > article{padding: 15px;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
}& > summary {& > div  > i{color: var(--clr-71563);
font-size: 14px;
}}
 }
/* Blog Grid :50 */
.MES50 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-71566);}
padding: 20px;

@media #{$large-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-71564);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES50 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-71566);}
padding: 20px;

@media #{$large-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-71564);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Blog Carousel:51 */
.MES51 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 0;

}
 }
.MES51 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 0;

}
 }
/* Cart button:52 */
.MES52 {
background-color: var(--clr-71569);
&:hover {background-color: var(--clr-71563);}
color: #ffffff;
padding: 15px 30px;

 }
/* Email Footer:53 */
.MES53 {
background-color: var(--clr-71563);
 }
.MES53 {
background-color: var(--clr-71563);
 }
/* Page Accordion:54 */
details.MES54 {
 }
